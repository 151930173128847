import { createAction, props } from '@ngrx/store';
import { UploadImageRequest } from '@app/image-viewer/models/upload-image-request';
import { FileType } from '@app/image-viewer/models/file-type';
import { ImageMetadata } from '../models/image-metadata';
import { PageRotation } from '../models/pdf-viewer.state';
import { ImageStoreFolderType } from '../models/image-store-folder-type';

export const loadImageMetadata = createAction(
  '[Image Viewer] Load selected image metadata',
  props<{ imageNumber: number }>(),
);

export const loadImageMetadataSuccess = createAction(
  '[Image Viewer] Load selected image metadata success',
  props<{ imageMetadata: ImageMetadata }>(),
);

export const loadImageMetadataFailure = createAction(
  '[Image Viewer] Load selected image metadata failure',
  props<{ error: string }>(),
);

export const loadImageFile = createAction(
  '[Image Viewer] Load image',
  props<{ imageNumber: number; imageStoreFolderType: ImageStoreFolderType }>(),
);

export const loadImageFileSuccess = createAction(
  '[Image Viewer] Load image success',
  props<{ file: any }>(),
);

export const loadImageFileFailure = createAction(
  '[Image Viewer] Load image failure',
  props<{ error: string }>(),
);

export const setNumberOfPages = createAction(
  '[Image Viewer] Set number of pages',
  props<{ pages: number }>(),
);

export const setCurrentPage = createAction(
  '[Image Viewer] Set current page',
  props<{ pageNumber: number }>(),
);

export const pageDown = createAction('[Image Viewer] page down');

export const pageUp = createAction('[Image Viewer] page up');

export const zoomOut = createAction('[Image Viewer] zoom out');

export const zoomIn = createAction('[Image Viewer] zoom in');

export const rotatePdf = createAction(
  '[Image Viewer] rotate selected image',
  props<{ imageStoreFolder: ImageStoreFolderType }>(),
);

export const rotatePdfSuccess = createAction(
  '[Image Viewer] rotate selected image success',
  props<{ pageRotation: PageRotation }>(),
);

export const rotatePdfFailure = createAction(
  '[Image Viewer] rotate selected image failure',
  props<{ error: string }>(),
);

export const resetViewer = createAction('[Image Viewer] reset viewer');

export const closeViewer = createAction('[Image Viewer] close viewer');

export const convertImageFile = createAction(
  '[Image Viewer] Convert image',
  props<{ request: UploadImageRequest }>(),
);

export const convertImageFileSuccess = createAction(
  '[Image Viewer] Convert image success',
  props<{ file: any; request: UploadImageRequest }>(),
);

export const convertImageFileFailure = createAction(
  '[Image Viewer] Convert image failure',
  props<{ error: string }>(),
);

export const createImageFile = createAction(
  '[Image Viewer] Create image',
  props<{ request: UploadImageRequest }>(),
);

export const createImageFileSuccess = createAction(
  '[Image Viewer] Create image success',
  props<{ imageNumber: number }>(),
);

export const createImageFileFailure = createAction(
  '[Image Viewer] Create image failure',
  props<{ error: string }>(),
);

export const clearSelectedFile = createAction(
  '[Image Viewer] Clear selected file',
);

export const downloadImage = createAction(
  '[Image Viewer] Download image file',
  props<{
    imageNumber: number;
    imageStoreFolder: ImageStoreFolderType;
    callBack: () => void;
  }>(),
);

export const downloadImageSuccess = createAction(
  '[Image Viewer] Download image file success',
  props<{ imageNumber: number; base64: string; callBack: () => void }>(),
);

export const downloadImageFailure = createAction(
  '[Image Viewer] Download image file failure',
  props<{ error: string }>(),
);

export const openSaveDialogSuccess = createAction(
  '[Image Viewer] Opened save dialog successfully',
);

export const logDownloadedImage = createAction(
  '[Image Viewer] Log downloaded image file',
  props<{ imageNumber: number }>(),
);

export const logDownloadedImageSuccess = createAction(
  '[Image Viewer] Log downloaded image file success',
);

export const logDownloadedImageFailure = createAction(
  '[Image Viewer] Log downloaded image file failure',
  props<{ error: string }>(),
);

export const viewUploadedImage = createAction(
  '[Image Viewer] View uploaded image file',
);

export const getAcceptedFileTypes = createAction(
  '[Image Viewer] Get accepted File Types',
);

export const getAcceptedFileTypesSuccess = createAction(
  '[Image Viewer] Get accepted File Types success',
  props<{ fileTypes: FileType[] }>(),
);

export const getAcceptedFileTypesFailure = createAction(
  '[Image Viewer] Get accepted File Types failure',
  props<{ error: string }>(),
);

export const initialiseConvertedImageMetadata = createAction(
  '[Image Viewer] Initialise converted image metadata',
  props<{ pages: PageRotation[] }>(),
);

export const rotateUploadedImage = createAction(
  '[Image Viewer] Rotate uploaded image',
);

export const rotateUploadedImageSuccess = createAction(
  '[Image Viewer] Rotate uploaded image success',
  props<{ pageRotation: PageRotation }>(),
);

export const resetPdfViewer = createAction('[Image Viewer] Reset PDF viewer');

export const rotateImage = createAction(
  '[Image Viewer] Rotate image',
  props<{ imageStoreFolder: ImageStoreFolderType }>(),
);
